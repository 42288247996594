import { reactive } from "vue";

const theme = reactive({
    agentLogo: "Footer.png",
    devLogo: "Header.png",
    apiUrl: "https://api.metaluxe.com.au/",
    bunnyApiUrl: "https://api.bunny.net/",
    accessKey: "07aeb6d1-92b0-48fc-9fa3-e202bc32d8753a016979-9f0b-4b1d-8027-5575c13bad47",
    streamToken: "5079da0a-f182-4f21-86ea-f142a85af61b",
    videoLibraryId: "130515",
    streamCdnUrl: "https://vz-27905601-b08.b-cdn.net",
    streamApiKey: "e92c5a71-f658-4b11-a7c7ce0660f5-529a-45b6",
    storageApiPassword: "f9389fa2-e401-4688-95dec0905e52-b366-4d64",
    cdnUrl: "https://glenside-pwa.b-cdn.net",
    devFolder: "/glenside-pwa",
    googleAnalytics: "",
    videosIcon: "/img/ui-theme/videos-icon.svg",
    rendersIcon: "/img/ui-theme/renders-icon.svg",
    rangeIcon: "/img/ui-theme/range-icon.svg",
    panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
    floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
    dashboardMp4: "theme/dashboard/4K_dashboard.mp4",

    // top level menu
    assetsMenuOrder: [
        'Glenside Showcase',
        '26 Vista Showcase',
        'News Corp Urban Showcase',
        'Ritz Carlton Marina Showcase'
    ],

    bed1MenuOrder: [
        'videos',
        'virtual-tour',
        'balcony-views',
        'floorplans',
        'range',
        'renders',
        'pdf'
    ],

    bed1Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Riverlea Boulevard Demo'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/2T2ciQRdd'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [
            'Proposal'
        ],
        pdfUrl: [
            'https://riverlea-pwa.b-cdn.net/pdfs/Proposal.pdf'
        ],
    },

    bed2Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Vista 3 Bed'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/5phsXhF-B'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed3Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [],
        panoramasUrl: [],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed4Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Marina Arm A'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/D_0nXoSSLNt'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },
});

export default { theme };
